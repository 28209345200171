<template>
	<div v-if="isInitialized && trancheItem">
		<frp-btn class="px-3" color="blue" height="34" dark elevation="0"
				 :loading="trancheItem?.orderCreating"
				 :disabled="!can(Permissions.TRANCHES_TRANCHE_SCHEDULE_ORDER) || mode === TrancheScheduleModeTypeEnum.EDIT || trancheItem?.status !== ApiTrancheStatusTypeEnum.ACCEPTED || (!trancheItem?.orderCreating && isOrderTranchePaymentApplicationCreating)"
				 @click="handleGenerateTranchePaymentApplication(trancheItem?.number)">
			{{ $t("buttons.orderApplication") }}
		</frp-btn>
		
		<frp-dialog v-model="isTranchePaymentApplicationGeneratingDialogOpened"
					:title="$t('dialogs.tranchePaymentApplicationGenerating.title',
					 	{
							amount: formatCurrency(trancheItem?.amount, 0),
					 		plannedDate: formatDate(trancheItem?.plannedDate, dateFormat)
						})"
					max-width="552">
			<template #content="{ onIntersect }">
				<v-container v-intersect="onIntersect" class="pt-0">
					<v-row class="d-flex justify-space-between">
						<v-col class="px-0">
							<div class="d-flex align-center rounded-sm px-4 py-4" :style="`border: 2px dashed ${colors.grey.base}`">
								<div class="d-flex align-center">
									<frp-icon src="ico_file_bar" :color="colors.primary.darken1" class="mr-3"></frp-icon>
									<span class="text-body-2 py-2" style="color: var(--v-primary-darken1)">
										{{ $t("common.tranchePaymentApplicationGeneratedFileName") }}
									</span>
								</div>
								<v-spacer></v-spacer>
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!trancheOrderFile"
										 :loading="isTranchePaymentApplicationGenerating"
										 @click="handleGenerateTrancheScheduleFile">
									{{ $t("buttons.download") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</template>
			
			<template #footer>
				<frp-btn color="primary" dark outlined
						 @click="isTranchePaymentApplicationGeneratingDialogOpened = false">
					{{ $t("buttons.close") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</div>
</template>

<script>
import { ApiTrancheStatusTypeEnum } from "@/api/tranches/types/ApiTrancheStatusTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { FileTypes } from "@/constants/fileTypes";
import AbortService from "@/services/abortService";
import { TrancheScheduleModeTypeEnum } from "@/store/tranches/modules/application/types/TrancheScheduleModeTypeEnum";
import {
	TrancheScheduleOrderConditionModeTypeEnum
} from "@/store/tranches/modules/application/types/TrancheScheduleOrderConditionModeTypeEnum";
import { TrancheScheduleSnapshotKeysEnum } from "@/store/tranches/modules/application/types/TrancheScheduleSnapshotKeysEnum";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import TranchesTrancheApplicationTrancheScheduleAcceptTable
	from "@/views/tranches/sections/trancheSchedule/acceptTable/TranchesTrancheApplicationTrancheScheduleAcceptTable.vue";
import TranchesTrancheApplicationTrancheScheduleLoader
	from "@/views/tranches/sections/trancheSchedule/TranchesTrancheApplicationTrancheScheduleLoader.vue";
import { saveAs } from "file-saver";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/tranches/modules/application/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const abortService = new AbortService();

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		trancheItem: Object
	},
	data() {
		return {
			namespace,
			FileTypes,
			ApiTrancheStatusTypeEnum,
			TrancheScheduleModeTypeEnum,
			TrancheScheduleOrderConditionModeTypeEnum,
			TrancheScheduleSnapshotKeysEnum,
			formatCurrency,
			formatDate,
			dateFormat,
			tranchePaymentApplicationFile: null,
			isTranchePaymentApplicationGenerating: false,
			isTranchePaymentApplicationGeneratingDialogOpened: false
		};
	},
	computed: {
		...mapGetters({
			isAcceptedTrancheScheduleStatus: getterTypes.isAcceptedTrancheScheduleStatus,
			lkProjectLink: getterTypes.lkProjectLink
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			mode: state => state.mode,
			trancheOrderFile: state => state.trancheOrderFile,
			trancheOrderFilename: state => state.trancheOrderFilename,
			applicationNumber: state => state.applicationNumber,
			orderConditionMode: state => state.orderConditionMode,
			isTrancheScheduleSaving: state => state.isTrancheScheduleSaving,
			isTrancheScheduleOrderConditionsSaving: state => state.isTrancheScheduleOrderConditionsSaving,
			isOrderTranchePaymentApplicationCreating: state => state.isOrderTranchePaymentApplicationCreating
		})
	},
	methods: {
		...mapActions({
			createOrderTranchePaymentApplication: actionTypes.createOrderTranchePaymentApplication
		}),
		...mapMutations({
			setTrancheOrderFile: mutationTypes.SET_TRANCHE_ORDER_FILE,
			setTrancheOrderFilename: mutationTypes.SET_TRANCHE_ORDER_FILENAME
		}),
		async handleGenerateTranchePaymentApplication() {
			await this.createOrderTranchePaymentApplication({ number: this.trancheItem.number });
			
			this.isTranchePaymentApplicationGeneratingDialogOpened = true;
		},
		async handleGenerateTrancheScheduleFile() {
			saveAs(this.trancheOrderFile, this.trancheOrderFilename);
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		isTranchePaymentApplicationGeneratingDialogOpened(value) {
			if(!value) {
				this.setTrancheOrderFile();
				this.setTrancheOrderFilename();
				this.isTranchePaymentApplicationGeneratingDialogOpened = false;
			}
		}
	},
	components: {
		FrpDialog,
		TranchesTrancheApplicationTrancheScheduleAcceptTable,
		FrpIcon,
		TranchesTrancheApplicationTrancheScheduleLoader,
		FrpBtn
	}
};
</script>
<style lang="scss">
.tranches-table-header-text {
  font-size: 0.875rem !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: 0.004em !important;
  line-height: 140% !important;
  color: var(--v-primary-lighten1) !important;
}
</style>